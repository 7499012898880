const agreeData = {
    'cn': [
        {
            "topTitle":"《少年西行记》用户协议",
        },
        {
           'content':[`本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。`]
        },
        {
            'content':[`如果您对本协议或少年西行记服务有意见或建议，可与少年西行记客服联系，我们会给予您必要的帮助。`]
        },
        {
            'content':[`少年西行记采取合理的方式提请用户注意的义务将通过如下方式实现：在本协议中少年西行记以明确的足以引起用户注意的颜色标记或加粗字体标注等合理方式提醒用户注意相关条款（需要强调的是，还包括用户应特别注意任何未明确标记的含有“不承担”、“免责”“不得”等形式用语的条款），该等条款的确认将导致用户在特定情况下的被动、不便、损失，请用户在确认同意本协议之前再次阅读上述条款。双方确认上述条款非属于《中华人民共和国民法典》第四百九十七条规定的“不合理地免除或者减轻其责任、加重对方责任、限制对方主要权利、排除对方主要权利”的条款。`,
            '如有任何需要说明条款的要求，请立即停止使用服务，同时联系少年西行记客服，若用户未致电或发送有说明条款要求的邮件至少年西行记而使用该协议，将被视为同意本协议，则双方在此确认少年西行记已依法履行了根据用户要求对相关条款进行说明的法定义务，少年西行记已给予用户充足的时间与充分的选择权来决定是否缔结本协议。',
            '鉴于少年西行记已依法明确了上述条款、履行了格式条款制订方的义务，用户点击同意或下一步，将被视为且应当被视为用户已经完全注意并同意了本协议所有条款，尤其是提醒用户注意的条款的合法性及有效性，用户不应当以少年西行记未对格式条款以合理方式提醒用户注意，或未根据用户要求尽到说明义务为理由，而声称或要求法院或其它任何第三方确认相关条款非法或无效。',]
        },
        {
            'content':[`重要须知`]
        },
        {
            'content':[`《少年西行记通行证用户服务协议》（以下简称“本协议”）是您（或称“用户”或“您”，指注册、登录、使用、浏览少年西行记服务的个人或组织）与少年西行记产品、程序及服务（以下简称“服务”）所订立的协议。`,
            '用户在使用少年西行记提供的少年西行记产品、程序及服务之前，少年西行记特别提醒用户认真阅读本协议的全部条款，特别是其中免除或者限制少年西行记责任的条款（该等条款通常含有“不负任何责任”、“无义务”、“不保证”等词汇）、限制用户权利的条款（该等条款通常含有“不得”、“不应”、“无权”等词汇）、法律适用和争议解决条款，这些条款应在中国法律所允许的范围内最大程度地适用。',
            '请您审慎阅读并选择接受或不接受本协议(未成年人或非为完全民事行为能力人应在监护人陪同下阅读）。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉相关服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。',
            '您对本协议的接受应被视为您接受本协议全部条款的约束，包括接受少年西行记对任一条款随时所做的任何修改。本协议可由少年西行记随时更新，更新后的协议条款一旦公布即代替原来的协议条款，您可在少年西行记网站查阅最新版协议条款。在少年西行记修改本协议相关条款之后，如果您不接受修改后的条款，请立即停止登录并停止使用少年西行记提供的服务，继续登录或使用少年西行记提供的服务将被视为您已接受了修改后的协议。',
            '如果用户未满18周岁的，需在监护人的陪同下阅读本协议，并在取得监护人对用户享用本公司提供的服务、向本公司支付费用的行为，以及对本协议全部条款的同意之后，方可享用本公司提供的服务。'
            ]
        },
        {
            'title': '一、服务内容',
            'content': [
                `1. 少年西行记通行证服务的具体内容由少年西行记根据实际情况提供，包括但不限于提供账号登录、账号注册、找回密码、账号锁定、账号信息查询、账号绑定等功能。 少年西行记保留随时变更、中止或终止部分或全部服务的权利。`,
                `2. 少年西行记仅提供少年西行记产品、程序及服务等相关服务，用户应自行配备上网的所需设备，包括但不限于智能设备(包括但不限于手机、平板电脑、电脑)、无线路由器或其他必备上网装置。`,
                '3. 用户自行负担上网所支付的与此服务相关的电信费用、网络使用等费用。',
                `4. 用户应使用正版软件体验少年西行记服务。`,
                '5. 少年西行记有权不定时地向用户发送业务公告等信息，通过邮件或短信等方式。',
            ]
        },
        
        {
            'title': '二、使用规则',
            'content': [
                `1. 用户应当保证注册时提供有效、详尽及准确的个人资料，并不断更新注册资料，符合有效、详尽、准确、完整的要求。提请知悉，所有提供的原始资料将被引用为注册资料。如果因注册信息不真实或更新不及时而引发相关问题，少年西行记不负任何责任。用户提供的个人资料（包括但不限于账号名称、头像、简介）不得出现违法、不良或不当信息，经少年西行记审核，如存在上述情况，少年西行记将不予注册；同时，在注册后，如发现以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法、不良或不当信息的，少年西行记有权不经通知单方采取限期改正、暂停使用、注销登记、收回账号等措施。`,
                `2. 因您个人原因导致的账号信息遗失，如需找回少年西行记账号信息，请按照少年西行记账号找回要求提供相应的信息，并确保您提供的信息合法真实有效，若提供的信息不符合要求，无法通过少年西行记安全验证及归属验证，少年西行记有权拒绝提供账号找回服务；若账号的凭证证明不再有效，我们有权拒绝支持账号找回。例如手机号二次出售，少年西行记可拒绝支持帮助找回原手机号绑定的账号。`,
                `3. 在需要终止使用少年西行记账号服务时，您可以申请注销您的少年西行记账号，具体申请流程请向少年西行记客服联系咨询：`,
                `(1)您仅能申请注销您本人的账号，并依照少年西行记的流程进行注销；`,
                `(2)您仍应对您在注销账号前且使用少年西行记服务期间的行为承担相应责任，同时少年西行记仍可保存您注销前的相关信息；`,
                `(3)注销成功后，账号信息、个人身份证信息、交易记录、账号权益等将被清除且无法恢复或提供。`,
                `4. 少年西行记有权审查您注册时所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；您有义务妥善保管用户账号及密码，并正确、安全地使用其用户账号及密码。`,
                `5. 您应当同意本协议的条款并按照页面上的提示完成全部的注册程序。在进行注册程序过程中点击“同意”或“下一步”按钮即表示与少年西行记达成协议，完全接受本协议项下的全部条款。`,
                `6. 用户注册成功后，少年西行记将授予每个用户一个少年西行记通行证账号及相应的密码。用户账号和密码由用户负责保管。用户有义务妥善保管其账号及密码，正确、安全地使用其账号及密码，并对登录后所持账号产生的行为依法享有权利和承担责任。在怀疑他人在使用您的账号或密码时，请您立即通知少年西行记。`,
                `7. 少年西行记应积极地采取技术与管理等合理措施保障用户账号的安全、有效。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。`,
                `8. 用户承诺以其真实身份信息注册少年西行记通行证账号，并保证所提供的个人身份资料信息真实、准确、完整、有效，依据法律规定和本协议约定对所提供的信息承担相应的法律责任。用户不得冒充他人，不得利用他人的名义发布任何信息；不得恶意使用注册账号功能导致其他误认；否则少年西行记有权立即停止提供服务，用户独自承担由此而产生的一切法律责任。`,
                `9. 少年西行记通行证账号的所有权归少年西行记所有，基于账号安全性，禁止使用他人的手机号、微信账号等注册少年西行记通行证账号。完成注册手续后，获得少年西行记通行证的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。少年西行记因经营需要，有权收回账号。`,
                `10.少年西行记提请您知悉，少年西行记无义务审核是否用户本人使用该组账号及密码，仅审核账号及密码是否与数据库中保存的一致，只要任何人输入的账号及密码与数据库中保存的一致，即可凭借该组账号及密码登录并获得服务，所以账号登录成功情形下，即使用户认为该账号登录行为并非其本人所为，少年西行记将不承担因此而产生的任何责任。`,
                `11.少年西行记根据用户的通知采取措施暂停用户账号的登录和使用的，少年西行记有权要求用户提供并核实与其注册身份信息相一致的个人有效身份信息或证明。少年西行记核实用户所提供的个人有效身份信息或证明与所注册的身份信息相一致的，应当按照用户请求或视有关情况采取措施暂停用户账号的登录和使用。少年西行记因根据用户的请求采取相应措施而造成用户或其他用户损失的，由提出该请求的用户自行承担责任。用户没有提供其个人有效身份信息或证明，或者用户提供的个人有效身份信息或证明与所注册的身份信息不一致的，少年西行记有权拒绝用户上述请求。因此造成用户损失的，由用户自行承担。用户为了维护其合法权益，向少年西行记提供与所注册的身份信息相一致的个人有效身份信息或证明时，少年西行记应当依法为用户提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。`,
                `12.用户在使用少年西行记相关产品、程序和服务过程中，必须同时遵循以下原则：`,
                `(1)遵守中国有关的法律法规；`,
                `(2)遵守所有与服务有关的网络协议、规定和程序；`,
                `(3)发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通知少年西行记。`,
                `(4)禁止用户在接受服务过程中进行以下侵害行为，包括但不限于：`,
                `(a)违反宪法确定的基本原则的；`,
                `(b)危害国家统一、主权和领土完整的；`,
                `(c)泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；`,
                `(d)煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；`,
                `(e)宣扬邪教、迷信的；`,
                `(f)散布谣言，扰乱社会秩序，破坏社会稳定的；`,
                `(g)宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；`,
                `(h)侮辱、诽谤他人，侵害他人合法权益的；`,
                `(i)违背社会公德的；`,
                `(j)宣扬、教唆使用外挂、私服以及木马的相关内容的；`,
                `(k)发布任何经少年西行记合理判断为不妥当或者少年西行记未认可的内容；`,
                `(5)有法律、行政法规和国家规定禁止的其他内容。`,
                `13.《少年西行记服务协议》以及少年西行记各个单项服务及/或产品条款和公告可由少年西行记随时更新。您在使用相关服务及/或产品时, 应关注并遵守社区服务所适用的相关规则和协议的规定(包括但不限于少年西行记在官方网站、官方微信公众号或少年西行记内不时发布并修订的协议、用户守则、玩家条例、公告及通知等内容)；遵守中华人民共和国相关法律法规(如果用户是中华人民共和国境外的使用者，还应遵守所属或所在国家或地区的法律法规)。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。`,
                `14.您应避免因使用少年西行记服务而使少年西行记及卷入政治和公共事件，否则少年西行记有权暂停或终止对您的服务。同时少年西行记有权立即停止对您注册的所有少年西行记通行证账号提供全部或部分服务，您应当为此独立承担一切责任。`,
                `15.您在使用少年西行记的服务时，必须遵守《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》、《网络信息内容生态治理规定》等中华人民共和国相关法律法规的规定，用户应同意不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:`,
                '(1)上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：',
                '(a)反对宪法确定的基本原则的;',
                '(b)危害国家统一、主权和领土完整的;',
                '(c)泄露国家秘密、危害国家安全或者损害国家荣誉和利益的;',
                '(d)煽动民族仇恨、民族歧视，破坏民族团结,或者侵害民族风俗、习惯的;',
                '(e)宣扬邪教、迷信的;',
                '(f)扰乱社会秩序，破坏社会稳定的;',
                '(g)宣扬淫秽、赌博、暴力或者教唆犯罪的;',
                '(h)侮辱或者诽谤他人，侵害他人合法权益的;',
                '(i)使用夸张标题，内容与标题严重不符的；',
                '(j)炒作绯闻、丑闻、劣迹等的；',
                '(k)不当评述自然灾害、重大事故等灾难的；',
                '(l)带有性暗示、性挑逗等易使人产生性联想的；',
                '(m)展现血腥、惊悚、残忍等致人身心不适的；',
                '(n)煽动人群歧视、地域歧视等的；',
                '(o)宣扬低俗、庸俗、媚俗内容的；',
                '(p)可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；',
                '(q)其他对网络生态造成不良影响的内容；',
                '(r)危害社会公德或者民族优秀文化传统的;',
                '(s)侵犯他人合法权益的；',
                '(t)含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；',
                '(u)含有法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；',
                '(2)进行以下侵害服务安全性的行为，包括但不限于：',
                '(a)以任何形式违反诚实信用原则，直接或间接采取组织、教唆、窃取、占有、使用、捡取、购买、转卖等手段侵犯任何第三方拥有使用权的账号、角色、虚拟货币、虚拟物品、虚拟道具等权益；',
                '(b)以任何积极或消极的形式侵犯或协助他人侵犯第三方权益的；',

                '(c)进行服务无关的行为，包括但不限于：为任何不当目的、非法目的及与少年西行记提供服务或产品未有直接关系而使用少年西行记服务；包括但不限于买卖号、角色、虚拟货币、虚拟道具、虚拟物品，宣传赌博、非法彩票等；',
                '(d)利用少年西行记提供的相关服务进行任何可能对互联网的正常运转造成不利影响的行为，包括但不限于以任何方式传输含有计算机病毒、破坏性程序的文件或其他任何可能对他人计算机或互联网的正常运转造成不利影响的软件或程序；',
                '(e)利用少年西行记提供的相关服务进行任何不利于少年西行记的行为，包括但不限于：进行任何洗钱、预付卡套现、购买和销售或以其他方式非法取得虚拟货币等违法行为；',
                '(3)发布违法、违规或少年西行记认为的任何不当信息。',
                '16.您根据本协议获得的少年西行记通行证账号和密码登录少年西行记产品、程序及服务时，应严格遵守少年西行记网站或相关服务、产品的相关服务协议及使用守则，包括但不限于《少年西行记服务协议》。',
            ]
        },


        {
            'title': '三、用户所提供内容的知识产权及授权',
            'content': [
                `1. 少年西行记尊重知识产权并注重保护用户享有的各项权利。在少年西行记产品服务中，您可能会通过上传、发布等各种方式向少年西行记提供原创内容。在此情况下，您仍然享有此等内容的完整知识产权。`,
                `2. 对于用户通过少年西行记服务（包括但不限于网页、论坛等）上传到少年西行记网站上可公开获取区域的任何内容，用户同意少年西行记在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。`,
                `3. 本公司依法享有少年西行记的一切合法权益（包括但不限于其中涉及的计算机软件著作权、美术作品著作权和专利权等知识产权，运营少年西行记软件的权利等）。`,
                `4. 少年西行记所体现的文字、LOGO（图形），以及在具体服务中使用的用以标识服务名称与来源的文字、LOGO（图形）等（包括但不限于应用的名称、LOGO、ICON，软件开发者的名称、LOGO、ICON等）。`,
                `5. 少年西行记拥有少年西行记服务中提供的所有资料的知识产权，包括但不限于商标权、版权、专利权。任何被授权的浏览、复制、和传播属于少年西行记运营的网站内的资料均不得用于商业目的。`,
                `6. 少年西行记所有的产品、技术与所有程序均属于少年西行记知识产权，未经少年西行记事先书面许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、上载、下载）使用，不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。否则，少年西行记将依法追究法律责任。`,
                `7. 本协议未明确授予您的权利均由本公司保留。`,
            ]
        },
        {
            'title': '四、链接及广告信息',
            'content': [
                `1. 少年西行记在官方网站、官方微信公众号、产品中所提供的所有链接，可能链接到其他第三方网站，提供该等网站的目的，是便利用户自行搜索或取得相关信息，少年西行记对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度。这些个人、公司或组织与少年西行记间不存在任何雇佣、委任、代理、合伙或其他类似的关系。`,
                `2. 少年西行记在官方网站、官方微信公众号、产品内可能刊登商业广告或其他推广活动信息。这些广告或推广活动信息是由广告商或商业服务提供者提供，并承担相应责任，少年西行记仅提供刊登内容的媒介。用户通过该等链接的网站或广告所购买的商品或服务，其交易行为仅存在于用户与该商品或服务的提供者之间，与少年西行记无关，少年西行记不就用户与该商品或服务的提供者之间所产生的交易行为承担任何法律责任，用户应予以特别留意。`
            ]
        },
        {
            'title': '五、用户信息保护',
            'content': [
                `1. 用户同意：个人隐私信息是指能够对用户进行个人辨识或涉及个人通信的信息，包含但不限于下列信息：用户的姓名，有效身份证件号码，联系方式，家庭地址，IP地址，电子邮件地址等信息。非个人隐私信息是指用户对本软件的操作状态以及使用习惯等一些明确且客观反映在少年西行记服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息。`,
                `2. 保护用户（特别是未成年人）的隐私是少年西行记的一项基本政策，未成年人应当在其监护人陪同下阅读，因此，若父母（监护人）希望未成年人（尤其是八岁以下子女）得以使用本服务，必须以父母（监护人）名义申请注册，在接受本服务时，父母（监护人）应判断本服务是否适合于未成年人。`,
                `3. 少年西行记注重对用户信息资源的保护，会使用各种安全技术和程序来保护用户信息资源不被未经授权的访问、使用和泄漏。除法律或政府要求或用户同意等原因外，少年西行记未经用户同意不会向除少年西行记及/或其合作的第三方公开、透露用户信息资源，但因下列原因而披露的除外：`,
                `(1)基于国家法律法规或其他可适用的法律法规的规定而对外披露；`,
                `(2)应有权的司法机关、行政机关及其他主管机关基于法定程序的要求而披露；`,
                '(3)应国家司法机关及其他有关机关基于法定程序的要求而披露；',
                '(4)少年西行记为了维护自己合法权益而向用户提起诉讼或者仲裁时；',
                '(5)用户本人或用户监护人授权披露的；',
                '(6)为保护少年西行记或您的合法权益且该措施为适当的披露；',
                '(7)在紧急情况下，为保护其他用户及第三方人身安全而披露；',
                '(8)为维护社会公众的利益。',
                '4. 除前述个人信息资料之外，在不透露单个用户隐私资料的前提下，您同意并授权少年西行记基于安全、用户体验优化等考虑而收集并使用您的其他必要信息，但少年西行记仅可为履行本协议之目的收集与使用该等信息。',
                '5. 尽管少年西行记对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。',
                '6. 在用户参加少年西行记产品并接受少年西行记服务的过程中产生并储存于少年西行记服务器中的任何数据信息，包括但不限于账号数据信息、角色数据信息、等级及虚拟物品数据信息等（但用户的姓名、身份证号、电话号码等个人身份数据信息除外），属于少年西行记产品的一部分，由少年西行记所有并进行管理，用户有权在遵守用户协议以及产品使用规则的前提下通过正当的途径对属于自身用户账号的数据信息进行修改、转移、抛弃等。'
            ]
        },
        {
            'title': '六、免责声明及惩罚措施',
            'content': [
                `1. 用户明确同意其使用少年西行记服务所存在的风险将完全由其自己承担；因其使用少年西行记服务而产生的一切后果也由其自己承担，少年西行记对用户不承担任何责任。`,
                `2. 少年西行记对以下事宜不作任何类型的担保，不论是明示的还是默示的：`,
                `(1)本协议项下少年西行记提供的相关服务将符合用户的要求；`,
                '(2)本协议项下少年西行记提供的相关服务不受干扰、及时提供、安全可靠或不会出错；',
                '(3)本协议项下少年西行记提供的相关服务少年西行记将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任；',
                '(4)本协议项下少年西行记提供的相关服务将不受不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机、电信部门原因及其他任何网络、技术、通信线路等外界或人为因素的影响；',
                '(5)本协议项下少年西行记不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由少年西行记所实际控制的任何网页上的内容，少年西行记不承担任何责任。',

                '3. 少年西行记保留判定用户的行为是否符合本协议约定的权利，如果用户违背了本协议的约定，少年西行记有权依据本协议中断对用户提供的服务，并有权对用户注册的所有少年西行记通行证账号采取包括但不限于以下一项或几项措施:',
                '(1)警告：警告仅仅是针对违反协议约定的用户做出的教育导向，它是用于正常管理少年西行记运行的一种方式；',
                '(2)禁言：关闭违规用户的部分或全部聊天，强制暂停用户账号的对话、上传功能，使用户账号无法与其他用户对话，直到此次处罚到期或是取消；',
                '(3)暂时冻结：将违规用户的服务内容进行限制，限制其局部服务功能，直到此次处罚到期或是取消；',
                '(4)永久冻结：将违规用户的账号进行限制，限制其部分服务功能，或永久限制；',
                '(5)暂时禁止登录：违规用户的用户账号将在一定的时间内暂时无法登录使用少年西行记服务的一个或部分平台，直到此次处罚到期或是取消；',
                '(6)永久禁止登录：违规用户的用户账号将永久无法登录使用少年西行记服务；',
                '(7)封停账号：暂停或永久停止违规用户账号登录少年西行记服务的权利；',
                '(8)承担法律责任：您的不当行为对他人或者少年西行记造成损害或者与现行法律规定相违背的，您要依法承担相应的民事、行政或刑事责任；',
                '(9)其他处罚措施：包括但不限于没收不正当利益（如您通过不正当途径获得的平台虚拟物品等）、倒扣数值（包括但不限于经验值等）等。',
            ]
        },
        {
            'title': '七、服务变更、中断、中止或终止',
            'content': [
                `1. 如因网站、平台、系统、应用软件、服务维护或升级的需要而需暂停服务，少年西行记将尽可能事先进行通告。`,
                `2. 用户在接受少年西行记相关服务时实施不正当行为的，少年西行记有权变更、中断、中止或终止对用户提供服务，该不当行为包括但不限于违约、违法、违反公序良俗、侵犯少年西行记或任何第三方之权益等。`,
                '3. 用户在接受少年西行记相关服务时实施本协议中有明确约定或属于少年西行记事先明确告知的应被终止服务的禁止性行为的，少年西行记有权终止对用户提供服务。',
                '4. 用户在注册账号时，提供虚假注册身份信息，少年西行记有权终止对用户提供服务。',
                '5. 用户利用少年西行记服务进行任何洗钱、预付卡套现、购买和销售或以其他方式非法取得虚拟货币等违法行为，少年西行记有权在不事先通知用户的情况下中止或终止部分或全部服务的权利。',
            ]
        },
        {
          'title': '八、损害赔偿',
          'content' :[
              `1. 用户同意无害地使用少年西行记网站和少年西行记产品、程序、账号系统及服务，避免少年西行记因下述行为或相关行为遭受来自第三方的任何投诉、诉讼、损失、损害、责任、成本和费用（包括但不限于律师费及其他费用）：您使用少年西行记网站和少年西行记产品、程序、账号系统及服务的行为；您的用户内容；您违反本协议的行为。`,
              `2. 用户内容是指用户下载、发布或以其他方式使用少年西行记网站与少年西行记产品、程序、账号系统及服务时产生的所有内容（例如：您的信息、图片、音乐或其他内容）；您是您的用户内容唯一的责任人，您将承担因您的用户内容披露而导致的您或任何第三方被识别的风险。`,
              `3. 用户同意保障和维护少年西行记及其他第三方的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给少年西行记或任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任，该等责任包括但不限于给少年西行记或其他第三方造成的一切直接和间接损失。`,
              '4. 因用户违反本协议约定之任意内容时，包括但不限于少年西行记通过内部的监测程序发现或经其他用户举报而发现用户有可能或已出现违约、违法、违反公序良俗、侵犯任何一方权益时，则少年西行记有权采取如下措施：包括但不限于限制用户账号的登录、限制用户在少年西行记产品中的活动和要求用户赔偿因用户从事上述行为而给少年西行记造成的一切直接和间接损失（包括但不限于人力成本损失、运营成本损失、商誉损失、维权损失等）、单方面解除本协议，用户在此明确同意承担上述违约责任。',
          ]
        },
        {
            'title': '九、通知',
            'content':[
                `1. 本协议可由少年西行记随时更新，更新后的协议条款一旦公布即代替原来的协议条款，少年西行记不再另行通知，用户可在本网站查阅最新版协议条款，敬请用户定期查询有关内容。`,
                `2. 在少年西行记修改本协议相关条款之后，如果用户不接受修改后的条款，请立即停止使用少年西行记提供的服务，用户继续使用少年西行记提供的服务将被视为已接受了修改后的协议。`,
                `3. 少年西行记所有发给用户的通知可通过少年西行记产品内、官方网站或官方微信公众号重要页面的公告、电子邮件或固定电话、手机、QQ、微信、短信等形式传送。同时，为使您及时、全面了解少年西行记提供的各项服务，您在此同意少年西行记可以向您的电子邮箱、手机发送商业性短信及商业性信息。
                `,
            ]

        },
        {
            'title': '十、用户信息使用',
            'content':[
                `1. 用户在此同意并授权少年西行记为履行本协议之目的收集您的用户信息，这些信息包括您在系统中注册的信息、您账号下的数据以及其他您在使用少年西行记服务的过程中向少年西行记提供或少年西行记基于安全、用户体验优化等考虑而需收集的信息，少年西行记对您的用户信息的使用将遵循本协议及相关法律的规定。`,
                `2. 用户在此同意少年西行记及/或其合作方可以根据用户的用户信息，通过短信、电话、邮件等各种方式向您提供关于产品或服务的活动信息、推广信息、商业信息等各类信息。`,
            ]

        },
        {
            'title': '十一、资费政策',
            'content':[
                `1. 本公司有权决定本软件及本游戏服务的收费方式和资费标准，本公司有权就本软件和本游戏服务在不同阶段确定不同的收费方式和资费标准，且本公司有权根据需要对资费政策随时进行调整。`,
                `具体收费方式、资费标准，包括但不限于本游戏软件的收费标准、软件中虚拟物品的使用权以及增值服务（如有）的具体收费标准等，均由本公司在APP STORE或其他APP发布渠道、游戏内道具商城、道具购买界面、官网等模块上，以网络行业通用的形式颁布。`,
                `用户有义务在选择前仔细阅读，一旦用户选择下载收费的本游戏软件、购买相关虚拟物品使用权或者接受相关服务，则表明用户已经充分理解并接受相应的收费方式和资费标准。`,
                `2、本公司有权根据需要，在软件中设立、添加、修改增值服务并发行增值服务代用币。`,
                `增值服务代用币通过使用人民币兑换获得，人民币一经兑换成增值服务代用币，则视为人民币已经使用，增值服务代用币不可兑换成人民币；增值服务代用币用于购买相关虚拟道具使用权或者接受相关增值服务。`,
                `在以用户购买虚拟物品的使用权或者以接受其他增值服务作为收费项目的情况下，如超出该虚拟物品的有效使用期限或增值服务的有效服务期限（不管用户是否已经实际使用或者享受服务），或者超出有效使用或服务次数，本公司即有权采取措施取消用户对上述虚拟物品的使用权或者停止提供增值服务。`,
            ]

        },
        {
            'title': '十二、其他规定',
            'content':[
                `1. 为避免争议，本协议涉及到的有关数据、有关技术方面的问题，用户同意以少年西行记服务器所储存的数据作为判断标准。少年西行记保证该数据的真实性。`,
                `2. 本协议所有条款的标题仅为方便阅读，本身并无实际涵义，不能作为本协议及相关条款涵义解释的依据。`,
                `3. 如本协议中的任何条款因任何原因被判定为完全或部分无效或不具有执行力的，本协议的其他条款仍应有效并且有执行力。`,
                `4. 少年西行记不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响少年西行记在将来行使该权利。`,
                `5. 少年西行记保留对本协议拥有修改，增补，删除，更新等权利。`,
                '6. 用户根据本协议获得的用户账号和密码登录少年西行记网站或接受其他少年西行记产品、程序及服务时，应严格遵守该网站或相关服务、产品的相关服务协议及使用守则，包括但不限于《少年西行记服务协议》；用户登录上述网站或接受上述服务、产品时即视为对相关服务协议及使用守则的接受。',
            ]

        },
        {
            'content':[
                '青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。'
            ]
        },
        {
            'title': '十三、联系方式',
            'content': [
                '如果您对本协议或少年西行记服务有任何意见或建议，可与少年西行记客服联系，我们会给予您必要的帮助。',
                '如果有任何问题请您发送至以下联系方式：',
                '客服QQ：485729307',
                '联系邮箱：485729307@qq.com',
                `官方交流群: 220714787 `
            ]
        },
        {
            'content': [
                '更新日期：2022年10月31日',
                '生效日期：2022年10月31日'
            ]
        }
    ],
    'en': [
        {
            'topTitle': `Terms of Service`,
        },
        {
            'content': [`The following Terms of Service and End User License Agreement (“EULA”) constitute an agreement between you (“You”) and “Clean Phone Application Provider”, governing the use of the software applications downloaded through Apple App Store or Google Play Store  (the “Licensed Application(s)”) . Questions, complaints or claims with respect to the Licensed Application should be directed to `,]
        },
        {
            'content': [`cleanphone.feedback@gmail.com`],
        },
        {
            'content':  [`You acknowledge and accept that (i) the previsions of this EULA shall not be deemed to set an agreement between You and Apple or Google(or its subsidiaries) in any manner whatsoever and (ii) Apple or Google and its subsidiaries are third party beneficiaries of this EULA, therefore Apple or Google and its subsidiaries shall have the right and are deemed to have accepted the right to enforce this EULA against You as a third party beneficiary thereof.`],
        },
        {
            'content': [`Please consider that the Licensed Application are licensed, not sold, to You for use only under the terms of this EULA, unless the same are accompanied by a different license agreement, in which case the terms of that different license agreement will govern the relationship between You and the Application Provider, subject to Your prior acceptance of that separate license agreement. The Application Provider reserves all rights not expressly granted to You hereinafter.`],
        },
        {
            'content': [`Application Provider may update the Licensed Application and/or the Services and/or this EULA from time to time in response to changing legal, technical or business developments. In such a case, Application Provider will take appropriate measures to inform You via the Licensed Application or the Services or otherwise, in accordance with the significance of the changes Application Provider makes. By continuing to access or use the Licensed Application and/or Services after updates become effective, You agree to be bound by the updated EULA.`],
        },
        {
            'title': 'a. Scope of License',
            'content': [
                `According to this EULA, the Application Provider grants you with a non-transferable license to use for personal and non-commercial use the Licensed Application on any Apple or GoogleiOS device that You own or control and in accordance with all applicable laws and Apple’s Usage Rules. This license does not allow You to use the Licensed Application on any Apple or GoogleiOS device that You do not own or control.`,
                `You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time, except that such Licensed Application may be accessed and used by other accounts associated with You via us.`,
                `You may not rent, lease, lend, sell, redistribute or sublicense the Licensed Application. You may not copy (except as expressly permitted by this license and Apple’s Usage Rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the Licensed Application). Any attempt to do so is a violation of the rights of the Application Provider and its licensors, if any. If You breach this restriction, You may be subject to prosecution and damages. The terms of the license will govern any upgrades provided by Application Provider that replace and/or supplement the Licensed Application, unless such upgrade is accompanied by a separate license, in which case the terms of that license shall prevail over this EULA.`,
                `You acknowledge and accept to comply with applicable third party terms of agreement related to the use of the Licensed Application.`,
                `The license covers all Apps published by us.`,
            ]
        },
        {
            'title': 'b. Consent to Use of Data',
            'content': [
                `You agree that Application Provider may collect and use technical data and related information, including but not limited to technical information about Your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to You (if any) related to the Licensed Application.
                   All data treated by the Application Provider shall be in compliance with EU Reg n. 679/2016, Swiss privacy laws and Application Provider’s privacy policy, as linked on the Licensed Application’s App Store or Google Play Store page.`,
                `Application Provider may use any information, as long as it is in a form that does not personally identify You, to improve its products or to provide services or technologies to You.`,
           ]
        },
        {
            'title': 'c. Termination',
            'content': [
                `This EULA shall commence on the date that you accept the same expressly or install or otherwise use the Licensed Application and shall end on the date of your disposal of the Licensed Application, save for earlier termination in accordance with this agreement. It is understood that the validity of this EULA and the validity of any purchase associated to the Licensed Application shall be construed independently from each other, this meaning that (i) the termination of such associated purchase shall not affect the validity of this EULA and (ii) the termination of this EULA shall not affect the validity of such associated purchase`,
                `Your rights under this license will terminate automatically without notice from the Application Provider if You attempt to circumvent any technical protection measures used in connection with the Licensed Application and/or Services or you otherwise use the Licensed Application and/or Services in breach of the terms of this EULA. Upon termination of the license, You shall cease all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.`,
            ]
        },
        {
            'title': 'd. Services and Third Party Materials',
            'content': [
                `The Licensed Application may enable access to Application Provider’s and third party services and web sites (collectively and individually, “Services”). Use of the Licensed Application and/or the Services may require Internet access and/or third party Services. In any case, the cost related to Internet access and third party Services – as well as any liability related to the above – shall be borne exclusively by You.`,
                `The Licensed Application may also enable access to certain virtual goods to be used within the Licensed Application and/or the Services. The purchase of these virtual goods shall be final and non-refundable and you acknowledge that, save if expressly allowed, the transfer of the same is prohibited. You acknowledge that (i) the Application Provider may modify or eliminate virtual goods at any time, without notice, and (ii) in case of termination of this EULA, you will have no right on the virtual goods for any reason whatsoever.`,
                `You understand that by using any of the Services, You may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search or entering of a particular URL may automatically and unintentionally generate links or references to objectionable material. Nevertheless, You agree to use the Services at Your sole risk and that the Application Provider shall not have any liability to You for content that may be found to be offensive, indecent, or objectionable.`,
                `Certain Services may display, include or make available content, data, information, applications or materials from third parties (“Third Party Materials”) or provide links to certain third party web sites. By using the Services, You acknowledge and agree that the Application Provider is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or web sites. The Application Provider does not warrant or endorse and does not assume and will not have any liability or responsibility to You or any other person for any third-party Services, Third Party Materials or web sites, or for any other materials, products, or services of third parties. Third Party Materials and links to other web sites are provided solely as a convenience to You.`,
                `Financial information displayed by any Services is for general informational purposes only and is not intended to be relied upon as investment advice. Before executing any securities transaction based upon information obtained through the Services, You should consult with a financial professional. Location data provided by any Services is for basic navigational purposes only and is not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage.`,
                `Health information displayed by any Services is for general informational purposes only. No medical advice is provided by any Services, and the information should not be so construed or used. Using and/or accessing the Services and/or providing personal or medical information to the Application Provider or third-party Services providers does not create a physician-patient relationship between you and the Application Provider or third-party Services providers. Nothing contained in the Licensed Application is intended to create a physician-patient relationship, to replace the services of a licensed, trained physician or health professional or to be a substitute for medical advice of a physician or trained health professional licensed in your state or country. You should not rely on anything contained in the Licensed Application, and you should consult a physician licensed in your state or country in all matters relating to your health. By using the Services, you agree, represent and warrant that your physician has notified you that you are able to participate in wellness and fitness programs, workouts, exercises or any of the related activities made available to you in connection with the Services. Activities promoted by the Services may pose risks even to those who are currently in good health. You hereby agree that you shall not make any health or medical related decision based in whole or in part on anything contained in the Licensed Application. The Licensed Application (i) has not been tested with individuals with health conditions; (ii) does not provide clinical advice; (iii) shall not be intended to be a medical device or treatment and, therefore, (iv) has not been cleared or approved by any governmental body or any ministry of health of/under any jurisdiction.`,
                `Neither the Application Provider, nor any of its content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of stock information or location data displayed by any Services.`,
                `You agree that any Services contain proprietary content, information and material that is protected by applicable intellectual property and other laws, including but not limited to copyright, and that You will not use such proprietary content, information or materials in any way whatsoever except for permitted use of the Services. No portion of the Services may be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, in any manner, and You shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, by trespass or burdening network capacity. You further agree not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that the Application Provider is not in any way responsible for any such use by You, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that You may receive as a result of using any of the Services.`,
                `In addition, third party Services and Third Party Materials that may be accessed from, displayed on or linked to from your Apple or GoogleiOS device are not available in all languages or in all countries. The Application Provider makes no representation that such Services and Materials are appropriate or available for use in any particular location. To the extent You choose to access such Services or Materials, You do so at Your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws. The Application Provider, and its licensors, reserve the right to change, suspend, remove, or disable access to any Services at any time without notice. In no event will the Application Provider be liable for the removal of or disabling of access to any such Services. The Application Provider may also impose limits on the use of or access to certain Services, in any case and without notice or liability.`,

            ]
        },
        {
            'title': 'e. Ownership',
            'content': [
                `The Application Provider and/or its licensor retain all right, title and interest in and to the Licensed Application, the Services and any content of the same, including, but not limited to, all copyrights, trademarks, trade secrets, trade names, proprietary rights, patents, titles, computer codes, audiovisual effects, themes, characters, characters names, stories, dialog, settings, artwork, sound effects, musical works, and moral rights whether registered or not and all applications thereof. You hereby acknowledge that no title or ownership in the Licensed Application, the Services and any content of the same is being transferred or assigned hereby and this EULA is not to be construed as a sale of any rights in the same.`,
            ]
        },
        {
            'title': 'f. Content Restrictions',
            'content': [
                `You are solely responsible for the content that you publish or display (hereinafter, “post”) on/trough the Licensed Application and/or the Service, or transmit to other users. You will not post or transmit to other users in any way whatsoever, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually explicit, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party’s rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity). Any content You may create, draft, design or in any way produce using the Licenced Application is for Your personal and non-commercial use only.`,
                `In case of breach of the previous obligations, You will be deemed liable and responsible for any damage or cost incurred or suffered by the Application Provider, Your profile on the Service will be permanently deleted and the greatest effort will be put into preventing you from rejoining the Service.`,
                `To the extent not prohibited by law, in no event shall Application Provider be liable for any third party claim arising from your non-compliance with this EULA and/or any applicable law. You hereby undertake to indemnify and hold Application Provider harmless from any damage, loss, cost, fee borne by the same in relation to your non-compliance with this EULA and/or any third party claim in relation to Your use of Licensed Application and/or the Service (including the content that you publish or display).`,
            ]
        },
        {
            'title': 'g. No Warranty',
            'content': [
                `You expressly acknowledge and agree that use of the Licensed Application and/or the Services is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you. To the maximum extent permitted by applicable law, the Licensed Application and any Services performed or provided by/via the Licensed Application are provided “as is” and “as available”, with all faults and without warranty of any kind, and application provider hereby disclaims all warranties and conditions with respect to the licensed application and any services, either express, implied or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and non-infringement of third party rights. Application Provider does not warrant against interference with your enjoyment of the licensed application, that the functions contained in, or services performed or provided by, the licensed application will meet your requirements, that the operation of the licensed application or services will be uninterrupted or error-free, or that defects in the licensed application or services will be corrected. No oral or written information or advice given by application provider or its authorized representative shall create a warranty. Should the Licensed Application or Services prove defective, you assume the entire cost of all necessary servicing, repair or correction.`,
                `In any case, the Application Provider shall be the sole responsible for (i) addressing any claim relating the licensed application and/or the services, including any third party’s intellectual property claim; and (ii) providing any maintenance and support service with respect to the licensed application as required under this EULA and/or applicable law.
It is understood that in the event of any failure of the Licensed Application to conform to any applicable warranties, You may notify Apple or Googleand require refund of the purchase price of the Licensed Application.`,
            ]
        },
        {
            'title': 'h. Limitation of Liability',
            'content': [
                `To the extent not prohibited by law, in no event shall Application Provider, or its licensor, be liable for personal injury, or any incidental, special, indirect or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss of data, business interruption or any other commercial damages or losses, arising out of or related to your use or inability to use the licensed application, however caused, regardless of the theory of liability (contract, tort or otherwise) and even if application provider has been advised of the possibility of such damages.`,
            ]
        },
        {
            'title': 'i. Legal compliance',
            'content': [
                `You may not use or otherwise export or re-export the Licensed Application except as authorized by Swiss law or United States law and the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List.`,
                `By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by Swiss law and/or United States law, including, without limitation, the development, design, manufacture or production of nuclear, missiles, or chemical or biological weapons.`,
            ]
        },
        {
            'title': 'j. Miscellanea',
            'content': [
                `Application Provider may assign this EULA or any right set out herein, in whole or in part, without your consent, to any person or entity at any time with or without the Licensed Application. You may not assign or delegate any rights or obligations under this EULA without Application Provider’s previous consent, and any unauthorized assignment and delegation by you is void.`,
                `Any failure by Application Provider to exercise or enforce any of its rights under this EULA does not waive its right to enforce such right. Any waiver of such rights shall only be effective if it is in writing and signed by an authorized representative of Application Provider.`,
            ]
        },
        {
            'title': 'k. Subscriptions',
            'content':[
                `All renewable subscriptions work the following way. You tap the continue button in the subscription popup/window. You then confirm your subscription, which is either on a weekly, monthly or yearly basis. The subscription will renew and the amount will be debited from your account as long as you don’t turn off your subscription. You can do so by going to App Store or Google Play Store, tapping the icon on the top right and then going to “Subscriptions”. You can also do it from your settings. In any case, you can ask us any question cleanphone.feedback@gmail.com and we will answer.`,
            ]
        },
        {
            'title': 'l. Governing law and competent Court',
            'content': [
                `The laws of Switzerland, excluding its conflicts of law rules, shall govern this license and your use of the Licensed Application. Your use of the Licensed Application may also be subject to other local, state, national, or international laws.`,
                `Any dispute, controversy or claim arising out of or relating to this EULA or the breach, termination or validity thereof shall be finally settled at Application Provider’s discretion (i) at your domicile’s competent Court; or (ii) at the Court of Geneva (Switzerland).`,
            ]
        },
    ],
    'tw': [
        {
            "topTitle": "《少年西行記》服務協議",
        },
        {
            'content': [`本協議的訂立、履行、解釋及爭議的解決均適用中華人民共和國法律並排除其他一切沖突法的適用。`]
        },
        {
            'content': [`如果您對本協議或少年西行記服務有意見或建議，可與少年西行記客服聯系，我們會給予您必要的幫助。`]
        },
        {
            'content': [`少年西行記采取合理的方式提請用戶註意的義務將通過如下方式實現：在本協議中少年西行記以明確的足以引起用戶註意的顏色標記或加粗字體標註等合理方式提醒用戶註意相關條款（需要強調的是，還包括用戶應特別註意任何未明確標記的含有「不承擔」、「免責」「不得」等形式用語的條款），該等條款的確認將導致用戶在特定情況下的被動、不便、損失，請用戶在確認同意本協議之前再次閱讀上述條款。雙方確認上述條款非屬於《中華人民共和國民法典》第四百九十七條規定的「不合理地免除或者減輕其責任、加重對方責任、限製對方主要權利、排除對方主要權利」的條款。`,
                '如有任何需要說明條款的要求，請立即停止使用服務，同時聯系少年西行記客服，若用戶未致電或發送有說明條款要求的郵件至少年西行記而使用該協議，將被視為同意本協議，則雙方在此確認少年西行記已依法履行了根據用戶要求對相關條款進行說明的法定義務，少年西行記已給予用戶充足的時間與充分的選擇權來決定是否締結本協議。',
                '鑒於少年西行記已依法明確了上述條款、履行了格式條款製訂方的義務，用戶點擊同意或下一步，將被視為且應當被視為用戶已經完全註意並同意了本協議所有條款，尤其是提醒用戶註意的條款的合法性及有效性，用戶不應當以少年西行記未對格式條款以合理方式提醒用戶註意，或未根據用戶要求盡到說明義務為理由，而聲稱或要求法院或其它任何第三方確認相關條款非法或無效。',]
        },
        {
            'content': [`重要須知`]
        },
        {
            'content': [`《少年西行記通行證用戶服務協議》（以下簡稱「本協議」）是您（或稱「用戶」或「您」，指註冊、登錄、使用、瀏覽少年西行記服務的個人或組織）與少年西行記產品、程序及服務（以下簡稱「服務」）所訂立的協議。`,
                '用戶在使用少年西行記提供的少年西行記產品、程序及服務之前，少年西行記特別提醒用戶認真閱讀本協議的全部條款，特別是其中免除或者限製少年西行記責任的條款（該等條款通常含有「不負任何責任」、「無義務」、「不保證」等詞匯）、限製用戶權利的條款（該等條款通常含有「不得」、「不應」、「無權」等詞匯）、法律適用和爭議解決條款，這些條款應在中國法律所允許的範圍內最大程度地適用。',
                '請您審慎閱讀並選擇接受或不接受本協議(未成年人或非為完全民事行為能力人應在監護人陪同下閱讀）。除非您接受本協議所有條款，否則您無權註冊、登錄或使用本協議所涉相關服務。您的註冊、登錄、使用等行為將視為對本協議的接受，並同意接受本協議各項條款的約束。',
                '您對本協議的接受應被視為您接受本協議全部條款的約束，包括接受少年西行記對任一條款隨時所做的任何修改。本協議可由少年西行記隨時更新，更新後的協議條款一旦公布即代替原來的協議條款，您可在少年西行記網站查閱最新版協議條款。在少年西行記修改本協議相關條款之後，如果您不接受修改後的條款，請立即停止登錄並停止使用少年西行記提供的服務，繼續登錄或使用少年西行記提供的服務將被視為您已接受了修改後的協議。',
                '如果用戶未滿18周歲的，需在監護人的陪同下閱讀本協議，並在取得監護人對用戶享用樂推公司提供的服務、向樂推公司支付費用的行為，以及對本協議全部條款的同意之後，方可享用樂推公司提供的服務。',
            ]
        },
        {
            'title': '一、服務內容',
            'content': [
                `1. 少年西行記通行證服務的具體內容由少年西行記根據實際情況提供，包括但不限於提供賬號登錄、賬號註冊、找回密碼、賬號鎖定、賬號信息查詢、賬號綁定等功能。少年西行記保留隨時變更、中止或終止部分或全部服務的權利。`,
                `2. 少年西行記僅提供少年西行記產品、程序及服務等相關服務，用戶應自行配備上網的所需設備，包括但不限於智能設備(包括但不限於手機、平板電腦、電腦)、無線路由器或其他必備上網裝置。`,
                '3. 用戶自行負擔上網所支付的與此服務相關的電信費用、網絡使用等費用。',
                `4. 用戶應使用正版軟件體驗少年西行記服務。`,
                '5. 少年西行記有權不定時地向用戶發送業務公告等信息，通過郵件或短信等方式。',
            ]
        },
        {
            'title': '二、使用規則',
            'content': [
                `1. 用戶應當保證註冊時提供有效、詳盡及準確的個人資料，並不斷更新註冊資料，符合有效、詳盡、準確、完整的要求。提請知悉，所有提供的原始資料將被引用為註冊資料。如果因註冊信息不真實或更新不及時而引發相關問題，少年西行記不負任何責任。用戶提供的個人資料（包括但不限於賬號名稱、頭像、簡介）不得出現違法、不良或不當信息，經少年西行記審核，如存在上述情況，少年西行記將不予註冊；同時，在註冊後，如發現以虛假信息騙取賬號名稱註冊，或其賬號頭像、簡介等註冊信息存在違法、不良或不當信息的，少年西行記有權不經通知單方采取限期改正、暫停使用、註銷登記、收回賬號等措施。`,
                `2. 因您個人原因導致的賬號信息遺失，如需找回少年西行記賬號信息，請按照少年西行記賬號找回要求提供相應的信息，並確保您提供的信息合法真實有效，若提供的信息不符合要求，無法通過少年西行記安全驗證及歸屬驗證，少年西行記有權拒絕提供賬號找回服務；若賬號的憑證證明不再有效，我們有權拒絕支持賬號找回。例如手機號二次出售，少年西行記可拒絕支持幫助找回原手機號綁定的賬號。`,
                `3. 在需要終止使用少年西行記賬號服務時，您可以申請註銷您的少年西行記賬號，具體申請流程請向少年西行記客服聯系咨詢：`,
                '(1)您僅能申請註銷您本人的賬號，並依照少年西行記的流程進行註銷；',
                '(2)您仍應對您在註銷賬號前且使用少年西行記服務期間的行為承擔相應責任，同時少年西行記仍可保存您註銷前的相關信息；',
                '(3)註銷成功後，賬號信息、個人身份證信息、交易記錄、賬號權益等將被清除且無法恢復或提供。',
                `4. 少年西行記有權審查您註冊時所提供的身份信息是否真實、有效，並應積極地采取技術與管理等合理措施保障用戶賬號的安全、有效；您有義務妥善保管用戶賬號及密碼，並正確、安全地使用其用戶賬號及密碼。`,
                '5. 您應當同意本協議的條款並按照頁面上的提示完成全部的註冊程序。在進行註冊程序過程中點擊「同意」或「下一步」按鈕即表示與少年西行記達成協議，完全接受本協議項下的全部條款。',
                `6. 用戶註冊成功後，少年西行記將授予每個用戶一個少年西行記通行證賬號及相應的密碼。用戶賬號和密碼由用戶負責保管。用戶有義務妥善保管其賬號及密碼，正確、安全地使用其賬號及密碼，並對登錄後所持賬號產生的行為依法享有權利和承擔責任。在懷疑他人在使用您的賬號或密碼時，請您立即通知少年西行記。`,
                `7. 少年西行記應積極地采取技術與管理等合理措施保障用戶賬號的安全、有效。
任何一方未盡上述義務導致賬號密碼遺失、賬號被盜等情形而給用戶和他人的民事權利造成損害的，應當承擔由此產生的法律責任。`,
                '8. 用戶承諾以其真實身份信息註冊少年西行記通行證賬號，並保證所提供的個人身份資料信息真實、準確、完整、有效，依據法律規定和本協議約定對所提供的信息承擔相應的法律責任。用戶不得冒充他人，不得利用他人的名義發布任何信息；不得惡意使用註冊賬號功能導致其他誤認；否則少年西行記有權立即停止提供服務，用戶獨自承擔由此而產生的一切法律責任。',
                '9. 少年西行記通行證賬號的所有權歸少年西行記所有，基於賬號安全性，禁止使用他人的手機號、微信賬號等註冊少年西行記通行證賬號。完成註冊手續後，獲得少年西行記通行證的使用權，該使用權僅屬於初始申請註冊人，禁止贈與、借用、租用、轉讓或售賣。少年西行記因經營需要，有權收回賬號。',
                '10. 少年西行記提請您知悉，少年西行記無義務審核是否用戶本人使用該組賬號及密碼，僅審核賬號及密碼是否與數據庫中保存的一致，只要任何人輸入的賬號及密碼與數據庫中保存的一致，即可憑借該組賬號及密碼登錄並獲得服務，所以賬號登錄成功情形下，即使用戶認為該賬號登錄行為並非其本人所為，少年西行記將不承擔因此而產生的任何責任。',
                '11. 少年西行記根據用戶的通知采取措施暫停用戶賬號的登錄和使用的，少年西行記有權要求用戶提供並核實與其註冊身份信息相一致的個人有效身份信息或證明。少年西行記核實用戶所提供的個人有效身份信息或證明與所註冊的身份信息相一致的，應當按照用戶請求或視有關情況采取措施暫停用戶賬號的登錄和使用。少年西行記因根據用戶的請求采取相應措施而造成用戶或其他用戶損失的，由提出該請求的用戶自行承擔責任。用戶沒有提供其個人有效身份信息或證明，或者用戶提供的個人有效身份信息或證明與所註冊的身份信息不一致的，少年西行記有權拒絕用戶上述請求。因此造成用戶損失的，由用戶自行承擔。用戶為了維護其合法權益，向少年西行記提供與所註冊的身份信息相一致的個人有效身份信息或證明時，少年西行記應當依法為用戶提供賬號註冊人證明、原始註冊信息等必要的協助和支持，並根據需要向有關行政機關和司法機關提供相關證據信息資料。',
                '12. 用戶在使用少年西行記相關產品、程序和服務過程中，必須同時遵循以下原則：',
                '(1)遵守中國有關的法律法規；',
                '(2)遵守所有與服務有關的網絡協議、規定和程序；',
                '(3)發現任何非法使用用戶賬號或賬號出現安全漏洞的情況，應立即通知少年西行記。',
                '(4)禁止用戶在接受服務過程中進行以下侵害行為，包括但不限於：',
                '(a)違反憲法確定的基本原則的；',
                '(b)危害國家統一、主權和領土完整的；',
                '(c)泄露國家秘密、危害國家安全或者損害國家榮譽和利益的；',
                '(d)煽動民族仇恨、民族歧視，破壞民族團結，或者侵害民族風俗、習慣的；',
                '(e)宣揚邪教、迷信的；',
                '(f)散布謠言，擾亂社會秩序，破壞社會穩定的；',
                '(g)宣揚淫穢、色情、賭博、暴力，或者教唆犯罪的；',
                '(h)侮辱、誹謗他人，侵害他人合法權益的；',
                '(i)違背社會公德的；',
                '(j)宣揚、教唆使用外掛、私服以及木馬的相關內容的；',
                '(k)發布任何經少年西行記合理判斷為不妥當或者少年西行記未認可的內容；',
                '(l)有法律、行政法規和國家規定禁止的其他內容。',
                '13.《少年西行記服務協議》以及少年西行記各個單項服務及/或產品條款和公告可由少年西行記隨時更新。您在使用相關服務及/或產品時, 應關註並遵守社區服務所適用的相關規則和協議的規定(包括但不限於少年西行記在官方網站、官方微信公眾號或少年西行記內不時發布並修訂的協議、用戶守則、玩家條例、公告及通知等內容)；遵守中華人民共和國相關法律法規(如果用戶是中華人民共和國境外的使用者，還應遵守所屬或所在國家或地區的法律法規)。如果您的行為違反了當地法律法規或道德風俗，您應當為此獨立承擔責任。',
                '14. 您在使用少年西行記的服務時，必須遵守《網絡安全法》、《計算機信息網絡國際聯網安全保護管理辦法》、《互聯網信息服務管理辦法》、《互聯網電子公告服務管理規定》、《維護互聯網安全的決定》、《互聯網新聞信息服務管理規定》、《網絡信息內容生態治理規定》等中華人民共和國相關法律法規的規定，用戶應同意不會利用本服務進行任何違法或不正當的活動，包括但不限於下列行為:',
                '(1)上載、展示、張貼、傳播或以其它方式傳送含有下列內容之一的信息：',
                '(a)反對憲法確定的基本原則的;',
                '(b)危害國家統一、主權和領土完整的;',
                '(c)泄露國家秘密、危害國家安全或者損害國家榮譽和利益的;',
                '(d)煽動民族仇恨、民族歧視，破壞民族團結,或者侵害民族風俗、習慣的;',
                '(e)宣揚邪教、迷信的;',
                '(f)擾亂社會秩序，破壞社會穩定的;',
                '(g)宣揚淫穢、賭博、暴力或者教唆犯罪的;',
                '(h)侮辱或者誹謗他人，侵害他人合法權益的;',
                '(i)使用誇張標題，內容與標題嚴重不符的；',
                '(j)炒作緋聞、醜聞、劣跡等的；',
                '(k)不當評述自然災害、重大事故等災難的；',
                '(l)帶有性暗示、性挑逗等易使人產生性聯想的；',
                '(m)展現血腥、驚悚、殘忍等致人身心不適的；',
                '(n)煽動人群歧視、地域歧視等的；',
                '(o)宣揚低俗、庸俗、媚俗內容的；',
                '(p)可能引發未成年人模仿不安全行為和違反社會公德行為、誘導未成年人不良嗜好等的；',
                '(q)其他對網絡生態造成不良影響的內容；',
                '(r)危害社會公德或者民族優秀文化傳統的;',
                '(s)侵犯他人合法權益的；',
                '(t)含有虛假、有害、脅迫、侵害他人隱私、騷擾、侵害、中傷、粗俗、猥褻、或其它道德上令人反感的內容；',
                '(u)含有法律、法規、規章、條例以及任何具有法律效力之規範所限製或禁止的其它內容的；',
                '(2)進行以下侵害服務安全性的行為，包括但不限於：',
                '(a)以任何形式違反誠實信用原則，直接或間接采取組織、教唆、竊取、占有、使用、撿取、購買、轉賣等手段侵犯任何第三方擁有使用權的賬號、角色、虛擬貨幣、虛擬物品、虛擬道具等權益；',
                '(b)以任何積極或消極的形式侵犯或協助他人侵犯第三方權益的；',
                '(c)進行服務無關的行為，包括但不限於：為任何不當目的、非法目的及與少年西行記提供服務或產品未有直接關系而使用少年西行記服務；包括但不限於買賣號、角色、虛擬貨幣、虛擬道具、虛擬物品，宣傳賭博、非法彩票等；',
                '(d)利用少年西行記提供的相關服務進行任何可能對互聯網的正常運轉造成不利影響的行為，包括但不限於以任何方式傳輸含有計算機病毒、破壞性程序的文件或其他任何可能對他人計算機或互聯網的正常運轉造成不利影響的軟件或程序；',
                '(e)利用少年西行記提供的相關服務進行任何不利於少年西行記的行為，包括但不限於：進行任何洗錢、預付卡套現、購買和銷售或以其他方式非法取得虛擬貨幣等違法行為；',
                '(3)發布違法、違規或少年西行記認為的任何不當信息。',
                '15. 您根據本協議獲得的少年西行記通行證賬號和密碼登錄少年西行記產品、程序及服務時，應嚴格遵守少年西行記網站或相關服務、產品的相關服務協議及使用守則，包括但不限於《少年西行記服務協議》。'
            ]
        },

        {
            'title': '三、用戶所提供內容的知識產權及授權',
            'content': [
                `1. 少年西行記尊重知識產權並註重保護用戶享有的各項權利。在少年西行記產品服務中，您可能會通過上傳、發布等各種方式向少年西行記提供原創內容。在此情況下，您仍然享有此等內容的完整知識產權。`,
                `2. 對於用戶通過少年西行記服務（包括但不限於網頁、論壇等）上傳到少年西行記網站上可公開獲取區域的任何內容，用戶同意少年西行記在全世界範圍內具有免費的、永久性的、不可撤銷的、非獨家的和完全再許可的權利和許可，以使用、復製、修改、改編、出版、翻譯、據以創作衍生作品、傳播、表演和展示此等內容（整體或部分），和/或將此等內容編入當前已知的或以後開發的其他任何形式的作品、媒體或技術中。`,
                `3. 樂推公司依法享有少年西行記的一切合法權益（包括但不限於其中涉及的計算機軟件著作權、美術作品著作權和專利權等知識產權，運營少年西行記軟件的權利等）。`,
                `4. 少年西行記所體現的文字、LOGO（圖形），以及在具體服務中使用的用以標識服務名稱與來源的文字、LOGO（圖形）等（包括但不限於應用的名稱、LOGO、ICON，軟件開發者的名稱、LOGO、ICON等）。`,
                `5. 少年西行記擁有少年西行記服務中提供的所有資料的知識產權，包括但不限於商標權、版權、專利權。任何被授權的瀏覽、復製、和傳播屬於少年西行記運營的網站內的資料均不得用於商業目的。`,
                `6. 少年西行記所有的產品、技術與所有程序均屬於少年西行記知識產權，未經少年西行記事先書面許可，任何人不得擅自（包括但不限於：以非法的方式復製、傳播、展示、上載、下載）使用，不得對該軟件進行反向工程（reverse engineer）、反向編譯（decompile）或反匯編（disassemble）。否則，少年西行記將依法追究法律責任。`,
                `7. 本協議未明確授予您的權利均由樂推公司保留。`,
            ]
        },
        {
            'title': '四、鏈接及廣告信息',
            'content': [
                `1. 少年西行記在官方網站、官方微信公眾號、產品中所提供的所有鏈接，可能鏈接到其他第三方網站，提供該等網站的目的，是便利用戶自行搜索或取得相關信息，少年西行記對於被鏈接的個人、公司或組織的網站所提供的產品、服務或信息，不擔保其真實性、完整性、實時性或可信度。這些個人、公司或組織與少年西行記間不存在任何雇傭、委任、代理、合夥或其他類似的關系。`,
                '2. 少年西行記在官方網站、官方微信公眾號、產品內可能刊登商業廣告或其他推廣活動信息。這些廣告或推廣活動信息是由廣告商或商業服務提供者提供，並承擔相應責任，少年西行記僅提供刊登內容的媒介。用戶通過該等鏈接的網站或廣告所購買的商品或服務，其交易行為僅存在於用戶與該商品或服務的提供者之間，與少年西行記無關，少年西行記不就用戶與該商品或服務的提供者之間所產生的交易行為承擔任何法律責任，用戶應予以特別留意。',
            ]
        },
        {
            'title': '五、 用戶信息保護',
            'content': [
                `1. 用戶同意：個人隱私信息是指能夠對用戶進行個人辨識或涉及個人通信的信息，包含但不限於下列信息：用戶的姓名，有效身份證件號碼，聯系方式，家庭地址，IP地址，電子郵件地址等信息。非個人隱私信息是指用戶對本軟件的操作狀態以及使用習慣等一些明確且客觀反映在少年西行記服務器端的基本記錄信息和其他一切個人隱私信息範圍外的普通信息。`,
                `2. 保護用戶（特別是未成年人）的隱私是少年西行記的一項基本政策，未成年人應當在其監護人陪同下閱讀，因此，若父母（監護人）希望未成年人（尤其是八歲以下子女）得以使用本服務，必須以父母（監護人）名義申請註冊，在接受本服務時，父母（監護人）應判斷本服務是否適合於未成年人。`,
                `3. 少年西行記註重對用戶信息資源的保護，會使用各種安全技術和程序來保護用戶信息資源不被未經授權的訪問、使用和泄漏。除法律或政府要求或用戶同意等原因外，少年西行記未經用戶同意不會向除少年西行記及/或其合作的第三方公開、透露用戶信息資源，但因下列原因而披露的除外：`,
                `(1)基於國家法律法規或其他可適用的法律法規的規定而對外披露；`,
                `(2)應有權的司法機關、行政機關及其他主管機關基於法定程序的要求而披露；`,
                '(3)應國家司法機關及其他有關機關基於法定程序的要求而披露；',
                '(4)少年西行記為了維護自己合法權益而向用戶提起訴訟或者仲裁時；',
                '(5)用戶本人或用戶監護人授權披露的；',
                '(6)為保護少年西行記或您的合法權益且該措施為適當的披露；',
                '(7)在緊急情況下，為保護其他用戶及第三方人身安全而披露；',
                '(8)為維護社會公眾的利益。',
                '4. 除前述個人信息資料之外，在不透露單個用戶隱私資料的前提下，您同意並授權少年西行記基於安全、用戶體驗優化等考慮而收集並使用您的其他必要信息，但少年西行記僅可為履行本協議之目的收集與使用該等信息。',
                '5. 盡管少年西行記對用戶的隱私權保護做了極大的努力，但是仍然不能保證現有的安全技術措施使用戶的技術信息等不受任何形式的損失。',
                '6. 在用戶參加少年西行記產品並接受少年西行記服務的過程中產生並儲存於少年西行記服務器中的任何數據信息，包括但不限於賬號數據信息、角色數據信息、等級及虛擬物品數據信息等（但用戶的姓名、身份證號、電話號碼等個人身份數據信息除外），屬於少年西行記產品的一部分，由少年西行記所有並進行管理，用戶有權在遵守用戶協議以及產品使用規則的前提下通過正當的途徑對屬於自身用戶賬號的數據信息進行修改、轉移、拋棄等。'
            ]
        },
        {
            'title': '六、 免責聲明及懲罰措施',
            'content': [
                `1. 用戶明確同意其使用少年西行記服務所存在的風險將完全由其自己承擔；因其使用少年西行記服務而產生的一切後果也由其自己承擔，少年西行記對用戶不承擔任何責任。`,
                `2. 少年西行記對以下事宜不作任何類型的擔保，不論是明示的還是默示的：`,
                `(1)本協議項下少年西行記提供的相關服務將符合用戶的要求；`,
                '(2)本協議項下少年西行記提供的相關服務不受幹擾、及時提供、安全可靠或不會出錯；',
                '(3)本協議項下少年西行記提供的相關服務少年西行記將盡力維護其安全性及方便性，但對服務中出現的信息（包括但不限於用戶發布的信息）刪除或儲存失敗不承擔任何責任；',
                '(4)本協議項下少年西行記提供的相關服務將不受不可抗力、計算機病毒、黑客攻擊、系統不穩定、用戶所在位置、用戶關機、電信部門原因及其他任何網絡、技術、通信線路等外界或人為因素的影響；',
                '(5)本協議項下少年西行記不保證為向用戶提供便利而設置的外部鏈接的準確性和完整性，同時，對於該等外部鏈接指向的不由少年西行記所實際控製的任何網頁上的內容，少年西行記不承擔任何責任。',
                '3. 少年西行記保留判定用戶的行為是否符合本協議約定的權利，如果用戶違背了本協議的約定，少年西行記有權依據本協議中斷對用戶提供的服務，並有權對用戶註冊的所有少年西行記通行證賬號采取包括但不限於以下一項或幾項措施:',
                '(1)警告：警告僅僅是針對違反協議約定的用戶做出的教育導向，它是用於正常管理少年西行記運行的一種方式；',
                '(2)禁言：關閉違規用戶的部分或全部聊天，強製暫停用戶賬號的對話、上傳功能，使用戶賬號無法與其他用戶對話，直到此次處罰到期或是取消；',
                '(3)暫時凍結：將違規用戶的服務內容進行限製，限製其局部服務功能，直到此次處罰到期或是取消；',
                '(4)永久凍結：將違規用戶的賬號進行限製，限製其部分服務功能，或永久限製；',
                '(5)暫時禁止登錄：違規用戶的用戶賬號將在一定的時間內暫時無法登錄使用少年西行記服務的一個或部分平臺，直到此次處罰到期或是取消；',
                '(6)永久禁止登錄：違規用戶的用戶賬號將永久無法登錄使用少年西行記服務；',
                '(7)封停賬號：暫停或永久停止違規用戶賬號登錄少年西行記服務的權利；',
                '(8)承擔法律責任：您的不當行為對他人或者少年西行記造成損害或者與現行法律規定相違背的，您要依法承擔相應的民事、行政或刑事責任；',
                '(9)其他處罰措施：包括但不限於沒收不正當利益（如您通過不正當途徑獲得的平臺虛擬物品等）、倒扣數值（包括但不限於經驗值等）等。',
            ]
        },
        {
            'title': '七、服務變更、中斷、中止或終止',
            'content': [
                `1. 如因網站、平臺、系統、應用軟件、服務維護或升級的需要而需暫停服務，少年西行記將盡可能事先進行通告。`,
                `2. 用戶在接受少年西行記相關服務時實施不正當行為的，少年西行記有權變更、中斷、中止或終止對用戶提供服務，該不當行為包括但不限於違約、違法、違反公序良俗、侵犯少年西行記或任何第三方之權益等。`,
                '3. 用戶在接受少年西行記相關服務時實施本協議中有明確約定或屬於少年西行記事先明確告知的應被終止服務的禁止性行為的，少年西行記有權終止對用戶提供服務。',
                '4. 用戶在註冊賬號時，提供虛假註冊身份信息，少年西行記有權終止對用戶提供服務。',
                '5. 用戶利用少年西行記服務進行任何洗錢、預付卡套現、購買和銷售或以其他方式非法取得虛擬貨幣等違法行為，少年西行記有權在不事先通知用戶的情況下中止或終止部分或全部服務的權利。',
            ]
        },
        {
            'title': '八、損害賠償',
            'content': [
                `1. 用戶同意無害地使用少年西行記網站和少年西行記產品、程序、賬號系統及服務，避免少年西行記因下述行為或相關行為遭受來自第三方的任何投訴、訴訟、損失、損害、責任、成本和費用（包括但不限於律師費及其他費用）：您使用少年西行記網站和少年西行記產品、程序、賬號系統及服務的行為；您的用戶內容；您違反本協議的行為。`,
                `2. 用戶內容是指用戶下載、發布或以其他方式使用少年西行記網站與少年西行記產品、程序、賬號系統及服務時產生的所有內容（例如：您的信息、圖片、音樂或其他內容）；您是您的用戶內容唯一的責任人，您將承擔因您的用戶內容披露而導致的您或任何第三方被識別的風險。`,
                `3. 用戶同意保障和維護少年西行記及其他第三方的利益，如因用戶違反有關法律、法規或本協議項下的任何條款而給少年西行記或任何其他第三方造成損失，用戶同意承擔由此造成的損害賠償責任，該等責任包括但不限於給少年西行記或其他第三方造成的一切直接和間接損失。`,
                '4. 因用戶違反本協議約定之任意內容時，包括但不限於少年西行記通過內部的監測程序發現或經其他用戶舉報而發現用戶有可能或已出現違約、違法、違反公序良俗、侵犯任何一方權益時，則少年西行記有權采取如下措施：包括但不限於限製用戶賬號的登錄、限製用戶在少年西行記產品中的活動和要求用戶賠償因用戶從事上述行為而給少年西行記造成的一切直接和間接損失（包括但不限於人力成本損失、運營成本損失、商譽損失、維權損失等）、單方面解除本協議，用戶在此明確同意承擔上述違約責任。',
            ]
        },
        {
            'title': '九、通知',
            'content': [
                `1. 本協議可由少年西行記隨時更新，更新後的協議條款一旦公布即代替原來的協議條款，少年西行記不再另行通知，用戶可在本網站查閱最新版協議條款，敬請用戶定期查詢有關內容。`,
                `2. 在少年西行記修改本協議相關條款之後，如果用戶不接受修改後的條款，請立即停止使用少年西行記提供的服務，用戶繼續使用少年西行記提供的服務將被視為已接受了修改後的協議。`,
                `3. 少年西行記所有發給用戶的通知可通過少年西行記產品內、官方網站或官方微信公眾號重要頁面的公告、電子郵件或固定電話、手機、QQ、微信、短信等形式傳送。同時，為使您及時、全面了解少年西行記提供的各項服務，您在此同意少年西行記可以向您的電子郵箱、手機發送商業性短信及商業性信息。`,
            ]

        },
        {
            'title': '十、用戶信息使用',
            'content': [
                `1. 用戶在此同意並授權少年西行記為履行本協議之目的收集您的用戶信息，這些信息包括您在系統中註冊的信息、您賬號下的數據以及其他您在使用少年西行記服務的過程中向少年西行記提供或少年西行記基於安全、用戶體驗優化等考慮而需收集的信息，少年西行記對您的用戶信息的使用將遵循本協議及相關法律的規定。`,
                `2. 用戶在此同意少年西行記及/或其合作方可以根據用戶的用戶信息，通過短信、電話、郵件等各種方式向您提供關於產品或服務的活動信息、推廣信息、商業信息等各類信息。`,
            ]

        },
        {
            'title': '十一、資費政策',
            'content': [
                `1. 樂推公司有權決定樂推軟件及樂推遊戲服務的收費方式和資費標準，樂推公司有權就樂推軟件和樂推遊戲服務在不同階段確定不同的收費方式和資費標準，且樂推公司有權根據需要對資費政策隨時進行調整。`,
                `具體收費方式、資費標準，包括但不限於樂推遊戲軟件的收費標準、軟件中虛擬物品的使用權以及增值服務（如有）的具體收費標準等，均由樂推公司在APP STORE或其他APP發布渠道、遊戲內道具商城、道具購買界面、官網等模塊上，以網絡行業通用的形式頒布。`,
                `用戶有義務在選擇前仔細閱讀，一旦用戶選擇下載收費的樂推遊戲軟件、購買相關虛擬物品使用權或者接受相關服務，則表明用戶已經充分理解並接受相應的收費方式和資費標準。`,
                `2. 樂推公司有權根據需要，在軟件中設立、添加、修改增值服務並發行增值服務代用幣。`,
                `增值服務代用幣通過使用人民幣兌換獲得，人民幣一經兌換成增值服務代用幣，則視為人民幣已經使用，增值服務代用幣不可兌換成人民幣；增值服務代用幣用於購買相關虛擬道具使用權或者接受相關增值服務。`,
                `在以用戶購買虛擬物品的使用權或者以接受其他增值服務作為收費項目的情況下，如超出該虛擬物品的有效使用期限或增值服務的有效服務期限（不管用戶是否已經實際使用或者享受服務），或者超出有效使用或服務次數，樂推公司即有權采取措施取消用戶對上述虛擬物品的使用權或者停止提供增值服務。`,
            ]

        },
        {
            'title': '十二、其他規定',
            'content': [
                `1. 為避免爭議，本協議涉及到的有關數據、有關技術方面的問題，用戶同意以少年西行記服務器所儲存的數據作為判斷標準。少年西行記保證該數據的真實性。`,
                `2. 本協議所有條款的標題僅為方便閱讀，本身並無實際涵義，不能作為本協議及相關條款涵義解釋的依據。`,
                `3. 如本協議中的任何條款因任何原因被判定為完全或部分無效或不具有執行力的，本協議的其他條款仍應有效並且有執行力。`,
                `4. 少年西行記不行使、未能及時行使或者未充分行使本協議或者按照法律規定所享有的權利，不應被視為放棄該權利，也不影響少年西行記在將來行使該權利。`,
                `5. 少年西行記保留對本協議擁有修改，增補，刪除，更新等權利。`,
                '6. 用戶根據本協議獲得的用戶賬號和密碼登錄少年西行記網站或接受其他少年西行記產品、程序及服務時，應嚴格遵守該網站或相關服務、產品的相關服務協議及使用守則，包括但不限於《少年西行記服務協議》；用戶登錄上述網站或接受上述服務、產品時即視為對相關服務協議及使用守則的接受。',
            ]

        },
        {
            'content': [
                '青少年用戶必須遵守全國青少年網絡文明公約：要善於網上學習，不瀏覽不良信息；要誠實友好交流，不侮辱欺詐他人；要增強自護意識，不隨意約會網友；要維護網絡安全，不破壞網絡秩序；要有益身心健康，不沈溺虛擬時空。'
            ]
        },
        {
            'title': '十三、聯系方式',
            'content': [
                '如果您對本協議或少年西行記服務有任何意見或建議，可與少年西行記客服聯系，我們會給予您必要的幫助。',
                '如果有任何问题请您发送至以下联系方式：',
                '客服QQ：485729307',
                '联系邮箱：485729307@qq.com',
                '官方交流群: 220714787'
            ]
        },
        {
            'content': [
                '更新日期：2022年10月31日',
                '生效日期：2022年10月31日'
            ]
        }
    ],
};

export { agreeData };
